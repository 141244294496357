import { isDebugMode } from "../../../../helpers/UiHelper";
import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import SleepViewTool from "../../../sleep/tools/SleepViewTool";
import SleepActivityCountTool from "../../../sleep/tools/SleepActivityCountTool";
import SleepRriTool from "../../../sleep/tools/SleepRriTool";
import CalcAPI from "../../../../api/CalcAPI";
import CommonHelper from "../../../../helpers/CommonHelper";
import SleepWakeTool from "../../../sleep/tools/SleepWakeTool";
import SleepActivitityTool from "../../../sleep/tools/SleepActivitityTool";
import { TabHeading, ComplianceHeading } from "../UiComponents";

export default function SleepTabPanel(props) {
  const {
    id,
    date,
    offsetActivityPoints,
    summary,
    hasNoActivityData,
    hasNoSleepData,
    loading,
    ntrainSleepStages,
    aiSleepLoading,
    displayFullDay,
    noonToNoonSleepData,
    noonToNoonSleepDataLoading,
    isSleepDebugMode,
  } = props;
  return (
    <div>
      <div className="sleep-stages-area" style={{ width: "100%", height: 350 }}>
        {hasNoSleepData ? (
          <div>
            <TabHeading>Sleep Stages</TabHeading>
            <SimpleNoDataMessageTool
              loading={loading}
              message={"Sorry, there is no sleep data for this day."}
            />
          </div>
        ) : (
          <div>
            <TabHeading>Sleep Stages</TabHeading>
            <div style={{ width: "100%", height: 300 }}>
              <SleepViewTool
                date={date}
                slots={summary.sleep.slots}
                displayFullDay={displayFullDay}
                dd={"philips"}
                uuid={id}
              />
            </div>
          </div>
        )}
      </div>

      {(isDebugMode() === false && isSleepDebugMode() === false) ||
      ntrainSleepStages === undefined ||
      hasNoSleepData ? null : (
        /**
         * Night Train deprecated
         */
        <div style={{ display: "none" }}>
          {/* <h2 className="heading">Night Train ({-diff} hrs)</h2> */}
          <h2 className="heading">Night Train</h2>
          <div style={{ width: "100%", height: 300, marginTop: 20 }}>
            {aiSleepLoading === true ? (
              "AI Sleep is loading..."
            ) : (
              <SleepViewTool
                displayFullDay={displayFullDay}
                date={date}
                slots={DrawHelper.transformAiSleepStages2(ntrainSleepStages)}
                uuid={id}
                dd={"nt"}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{ cursor: "pointer", marginTop: "1rem" }}
              onClick={async () => {
                setRrsDownloading(true);
                let pld = await CalcAPI.getNTrainIo(id, date);
                // console.log("ntrain data = ", pld);
                CommonHelper.downloadFile(
                  `ntrain_${date}.json`,
                  JSON.stringify(pld)
                );
                setRrsDownloading(false);
              }}
            >
              {rrsDownloading === true
                ? "loading..."
                : "download NTrain input/Output data"}
            </span>
            {ntrainId === undefined ? null : (
              <div
                style={{
                  fontSize: "12px",
                  fontStyle: "italic",
                  marginTop: "1rem",
                }}
              >
                {"NTrain ID: " + ntrainId}
              </div>
            )}
          </div>
        </div>
      )}

      {isDebugMode() === false ? null : (
        <div className="sleep-wake-area" style={{ marginTop: 30 }}>
          <ComplianceHeading className="heading">Sleep/Wake</ComplianceHeading>
          <div style={{ width: "100%", height: 260 }}>
            {/* <AiSleepViewTool date={date} uuid={id} /> */}
            <SleepWakeTool
              uuid={id}
              date={date}
              sleepDataResponse={noonToNoonSleepData}
              sleepDataLoading={noonToNoonSleepDataLoading}
              sleepAndWakeUpTime={
                hasNoSleepData
                  ? undefined
                  : {
                      time_to_sleep: summary.sleep?.time_to_sleep,
                      time_to_wake_up: summary.sleep?.time_to_wake_up,
                      start_date: summary.sleep?.start_date,
                      end_date: summary.sleep?.end_date,
                    }
              }
            />
          </div>
        </div>
      )}

      {isDebugMode() === false ? null : (
        <div
          className="sleep-activity-count-area"
          style={{
            width: "100%",
            height: 250,
            marginTop: 20,
            // border: "1px solid red",
          }}
        >
          <ComplianceHeading>Activity Count</ComplianceHeading>

          <div
            style={{
              width: "100%",
              height: 200,
              marginTop: 10,
              // border: "1px solid blue",
            }}
          >
            <SleepActivityCountTool
              sleepData={noonToNoonSleepData}
              uuid={id}
              date={date}
              loading={noonToNoonSleepDataLoading}
            />
          </div>
        </div>
      )}

      {isDebugMode() === false ? null : (
        <div
          className="sleep-rri-area"
          style={{ width: "100%", height: 350, marginTop: 20 }}
        >
          <ComplianceHeading>Sleep RRI</ComplianceHeading>

          <div style={{ width: "100%", height: 240, marginTop: 10 }}>
            {/* <AiSleepTool date={date} uuid={id} /> */}
            {/* <SimpleNoDataMessageTool
            message={"No sleep RRI data for this day."}
          /> */}
            <SleepRriTool uuid={id} date={date} />
          </div>
        </div>
      )}

      {isDebugMode() === false || hasNoActivityData ? null : (
        <div className="sleep-activity-area" style={{ marginTop: 30 }}>
          <ComplianceHeading className="heading">Activity</ComplianceHeading>
          <SleepActivitityTool
            points={offsetActivityPoints}
            date={date}
            uuid={id}
          />
        </div>
      )}

      {/* {isDebugMode() === false ? null : (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <span
          style={{ cursor: "pointer" }}
          onClick={async () => {
            setRrsDownloading(true);
            let pld = await DoctorAPI.getSleepRRIntervals(id, date);
            console.log("sleep rr intervals = ", pld);
            CommonHelper.downloadFile(
              `rr_intervals_${date}.json`,
              JSON.stringify(pld)
            );
            setRrsDownloading(false);
          }}
        >
          {rrsDownloading === true
            ? "loading..."
            : "download RR-intervals data"}
        </span>
      </div>
    )} */}
    </div>
  );
}
