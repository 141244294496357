import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  GRID_DASH_ARRAY,
  LINE_COLORS,
  xaxisTimeFormatter,
} from "../ui/templates";
import DoctorAPI from "../../api/DoctorAPI";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import marker from "../../assets/images/warning-exclaimation.svg";
import markerBackground from "../../assets/images/warning-triangle-yellow.svg";
import markerBackgroundRed from "../../assets/images/warning-triangle-red.svg";
import sprint from "../../assets/images/confidence-motion.svg";
import venous from "../../assets/images/venous-pooling.svg";
import NiceModal from "../modals/NiceModal";

// const Y_DOMAIN = [80, 100];
// const SPOTS_PER_DAY = 12 * 24;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
];

const calculateWeight = (minutesBefore) => {
  if (minutesBefore <= 5) {
    return 0.9;
  } else if (minutesBefore > 5 && minutesBefore <= 10) {
    return 0.05;
  } else if (minutesBefore > 10) {
    return 0.05;
  } else {
    return 1;
  }
};
function calculateBWMAFromArrayOld(data, keyName, t0, rateOfChange) {
  /*
   * Weighted moving average bi direction
   */
  let length = data.length;
  if (!data || length === 0) {
    return 0;
  }

  let sum = 0;
  let validSpots = 0;
  let _f = undefined;

  for (let i = 0; i <= length - 1; i++) {
    let current = data[i][keyName];
    let t1 = moment(data[i].t);
    let _diff = moment(t0).diff(t1, "minutes");
    // let t1 = +data[i].t
    // let _diff = (+t0 - t1) / (60000)
    let _weight = rateOfChange < 0 ? calculateWeight(_diff) : 1;
    // let _weight = 1;
    //   console.log("_diff", rateOfChange, _weight);

    if (current) {
      _f = current * _weight;
      sum += current * _weight;
      validSpots += _weight;
    }
  }

  let avg = (sum / validSpots).toFixed(1);
  // let avg = validSpots === 1 ? null : (sum / validSpots).toFixed(1);
  if (isNaN(avg)) {
    avg = _f;
  }
  return avg;
}

function calculateBWMAFromArray(data, keyName, t0, rateOfChange) {
  /*
   * Weighted moving average bi direction
   */
  let length = data.length;
  if (!data || length === 0) {
    return 0;
  }

  let sum = 0;
  let validSpots = 0;
  let _f = undefined;

  for (let i = 0; i <= length - 1; i++) {
    let current = data[i][keyName];
    // let t1 = moment(data[i].t);
    // let _diff = moment(t0).diff(t1, "minutes");
    let t1 = +data[i].t;
    let _diff = (+t0 - t1) / 60000;
    let _weight = rateOfChange < 0 ? calculateWeight(_diff) : 1;
    // let _weight = 1;
    //   console.log("_diff", rateOfChange, _weight);

    if (current) {
      _f = current * _weight;
      sum += current * _weight;
      validSpots += _weight;
    }
  }

  let avg = (sum / validSpots).toFixed(1);
  // let avg = validSpots === 1 ? null : (sum / validSpots).toFixed(1);
  if (isNaN(avg)) {
    avg = _f;
  }
  return avg;
}

function calculateBWMAFromArrayV1(data, keyName, t0, rateOfChange) {
  /*
   * Trimmed average
   */
  let length = data.length;
  if (!data || length === 0) {
    return 0;
  }

  let sum = 0;
  let validSpots = 0;
  let _f = undefined;

  let spo2List = data.filter((x) => x.spo2).map((x) => x.spo2);

  // let avg = (sum / validSpots).toFixed(1);
  const _max = Math.max(...spo2List);
  const _min = Math.min(...spo2List);
  const _sum = spo2List.reduce((acc, curr) => acc + curr, 0);

  let avg = null;
  if (spo2List.length <= 2) {
    avg = _sum / spo2List.length;
  } else {
    avg = (_sum - _max - _min) / (spo2List.length - 2);
  }
  // if (avg < 192) {
  //   console.log({ spo2List, avg, time: moment(data[0].t).format("DD HH:mm") });
  // }
  // let avg = validSpots === 1 ? null : (sum / validSpots).toFixed(1);
  if (isNaN(avg)) {
    avg = _f;
  }
  avg = +avg.toFixed(2);
  return avg;
}

function calculateEMAFromArray(data, keyName, t0, lastBaseline, w) {
  /*
   * exponential moving average
   * 30/sec > 0.07
   * 5/min > 0.3
   */
  if (!data || data.length === 0) {
    return 0;
  }

  // step 1: calculate the average of all valid values of the 15 minute window
  let valueList = data.filter((x) => x[keyName]).map((x) => x[keyName]);
  let validLength = valueList.length;
  const _sum = valueList.reduce((accu, currValue) => {
    return accu + currValue;
  }, 0);
  const _avg = _sum / valueList.length;

  // step 2, Initialize and calculate the baseline for x_t, using the formula `w*currentSpo2 + (1-w)*previousBaseline`
  // if lastBaseline does not exist of is 0, then take current average
  let baseline;
  const _w = w;
  if (lastBaseline === 0) {
    baseline = _avg;
  } else {
    baseline = +valueList[validLength - 1] * _w + lastBaseline * (1 - _w);
  }
  if (isNaN(baseline)) {
    return undefined;
  }
  baseline = baseline.toFixed(2);
  return baseline;
}

const getMedian = (array) => {
  const sortedArray = [...array].sort((a, b) => a - b);
  const mid = Math.floor(sortedArray.length / 2);
  if (sortedArray.length % 2 === 0) {
    return (sortedArray[mid - 1] + sortedArray[mid]) / 2;
  } else {
    return sortedArray[mid];
  }
};
function calculateMovingAverageForSpo2Old(data, window) {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  // const windowInMs = window * 60 * 1000;
  for (let i = 0; i <= length - 1; i++) {
    const currentSpo2 = data[i].spo2;
    const prevSpo2 = i > 0 ? data[i - 1].spo2 : currentSpo2;

    if (currentSpo2) {
      let hasValid = false;
      let t0 = moment(data[i].slot);
      // let t0 = +data[i].slot;

      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        // let t1 = +data[j].slot;
        let df = t0.diff(moment(data[j].slot), "minutes");
        // let df = t0 - t1;
        // console.log(df);
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }

      let _wma = calculateBWMAFromArrayOld(
        _arr,
        "spo2",
        t0,
        currentSpo2 - prevSpo2
      );
      if (_wma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
        });
        continue;
      }

      // let adpt = null;
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
        baseline: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
}

function calculateMovingAverageForSpo2(data, window, algorithm) {
  if (!data || data.length === 0) {
    return;
  }
  const length = data.length;
  // console.log(length);

  let spotsWithMaAndAdaptive = [];
  const windowInMs = window * 60 * 1000;
  let lastBaseline = 0;
  for (let i = 0; i <= length - 1; i++) {
    const currentSpo2 = data[i].spo2;
    const prevSpo2 = i > 0 ? data[i - 1].spo2 : currentSpo2;
    const delta = currentSpo2 - prevSpo2;
    const currentTs = data[i].slot;
    const prevTs = i > 0 ? data[i - 1].slot : currentTs;
    const _gap = (currentTs - prevTs) / 1000;
    let w = 0.1;
    if (_gap <= 33) {
      // for 30/sec
      w = 0.07;
    } else if (_gap > 33 && _gap <= 330) {
      // for 5/min (300sec)
      w = 0.3;
    } else {
      w = 0.1;
    }

    if (currentSpo2) {
      let t0 = +data[i].slot;

      // this is for taking the recent 15 minutes slice of data, including the current point.
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let t1 = +data[j].slot;
        let df = t0 - t1;
        if (df < windowInMs) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }

      let _wma = [];
      if (algorithm === 0) {
        _wma = calculateBWMAFromArray(_arr, "spo2", t0, delta);
      } else if (algorithm === 1) {
        _wma = calculateBWMAFromArrayV1(_arr, "spo2", t0, delta);
      } else if (algorithm === 2) {
      } else if (algorithm === 3) {
      } else if (algorithm === 4) {
        if (_gap < 15 * 60) {
          _wma = calculateEMAFromArray(_arr, "spo2", t0, lastBaseline, w);
        } else {
          _wma = calculateEMAFromArray(_arr, "spo2", t0, lastBaseline, 0.99);
        }
        lastBaseline = _wma;
      } else {
        _wma = calculateEMAFromArray(_arr, "spo2", t0, lastBaseline, w);
        lastBaseline = _wma;
      } // let _wma = calculateBWMAFromArray( // let _wma = calculateBWMAFromArrayV1( // trimmed average

      if (_wma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
        });
        continue;
      }
      // console.log({ bl: _wma, t: moment(t0).format("HH:mm"), arr: _arr });

      // let adpt = null;
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
        baseline: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
    }
  }
  // console.log(spotsWithMaAndAdaptive);
  // console.log(
  //   spotsWithMaAndAdaptive.map((x) => {
  //     return { bl: x.baseline, t: moment(x.t).format("HH:mm") };
  //   })
  // );

  return spotsWithMaAndAdaptive;
}

const fillSpo2MissingSpot = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  let emptySpots = [];
  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gapInMinutes = moment(nextPoints).diff(currentPoints, "minutes");
    emptySpots.push(data[i]);
    if (gapInMinutes > 10) {
      let shadowSlot = moment(currentPoints).add(1, "minutes").valueOf();
      emptySpots.push({
        spo2: null,
        baseline: null,
        t: shadowSlot,
      });
    } else {
    }
  }
  emptySpots.push(data[data.length - 1]);
  return emptySpots;
};

const algoMap = [
  "production",
  "trimmed average",
  "median filter",
  "Exponentially Weighted",
  "EMA",
];

const calculateEMAFromData = (arr) => {
  if (!arr || arr.length === 0) {
    return [];
  }
  const w = 0.07; // smoothing factor
  let _points = arr.map((x, i) => {
    if (i === 0) {
      return { ...x, baseline: null };
    }
    const currentPoint = x;
    const prevBaseline = i === 0 ? null : arr[i - 1].baseline;
    const currentSpo2 = currentPoint.spo2;
    if (!currentSpo2) {
      return { ...x, baseline: null };
    }
    const currentBaseline = currentSpo2 * w + prevBaseline * (1 - w);
    return { ...x, baseline: currentBaseline };
  });
  return _points;
};

const PatientDaySpo2BaselineDebugsTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    tz = 0,
    baselineWindow = 15,
    points = [],
    loading = false,
    algorithm = 0,
    motionMarkerTimestamps = [],
  } = props;
  const [ref, { width, height }] = useDimensions();
  // const [loading, setLoading] = useState(false);
  const [spo2RawData, setSpo2RawData] = useState([]);
  const [spo2ProcessedData, setSpo2ProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
  });
  const [dataLength, setDataLength] = useState(0);
  const [activeLabel, setActiveLabel] = useState(undefined);
  const [showConfidenceMessage, setShowConfidenceMessage] = useState(false);
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (!points || points.length === 0) {
      return;
    }
    // console.log(points);

    let _points = points.filter((x) => x?.spo2_q && x?.spo2 > 0);
    // let _points = points.filter((x) => x?.spo2_q);
    // console.log(_points);

    let start = performance.now();
    const windowInMinutes = 15 * 2;
    // let _map = calculateEMAFromData(_points);

    let _map = calculateMovingAverageForSpo2(
      _points,
      windowInMinutes,
      algorithm
    );
    // let _map = calculateMovingAverageForSpo2Old(_points, 15);
    // console.log(_map);

    console.log(
      `debug tool spo2 baseline took ${(performance.now() - start).toFixed(
        1
      )} ms`
    );

    // console.log("_map", _map);
    _points = fillSpo2MissingSpot(_map);
    setSpo2RawData(_map);
    setSpo2ProcessedData(_points);
    // setDataLength(_points.length);
  }, [date, uuid, points]);

  let extraLines = DrawHelper.getExtraDottedChartsData(
    spo2ProcessedData,
    "spo2"
  );
  let spo2WithGaps = spo2ProcessedData;
  for (let sp of spo2WithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][0][`spo2_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][1][`spo2_gap_${el}`];
      }

      // spo2WithGaps;
    }
  }

  // spo2ProcessedData.map((x, i) => {
  //   if (!x.dashed) {
  //     return 0;
  //   }
  //   if (x.dashed && !spo2ProcessedData[i + 1].dashed) {
  //     extraLines.push()
  //   }
  // });
  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let yticks = [];
  let spo2List = spo2ProcessedData.map((x) => x.spo2).filter((x) => x !== null);

  let minSpo2 = Math.min(...spo2List);
  let ymin = Math.floor(minSpo2 / 10) * 10;
  if (ymin >= 90) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 90 && ymin >= 80) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 80 && ymin >= 70) {
    yticks = [70, 80, 90, 100];
  } else {
    const gap = Math.round((100 - ymin) / 5);
    for (let i = ymin; i <= 100; i += gap) {
      yticks.push(i);
    }
    yticks.push(100);
  }
  // console.log(yticks, ymin, spo2List);

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();
  // console.log("yticks", yticks);
  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  // const demoMarkerTime = [0.99, 6.75]; // max 1 per hour
  const demoMarkerTime = [];
  const demoVPMarkerTime = [];

  const motionTimes = motionMarkerTimestamps.map((x) => {
    const hour = x.split(":")[0];
    return +hour;
  });

  const Marker = (props) => {
    const { time, type = "" } = props;
    const iconCap = {
      motion: sprint,
      marker: marker,
      venous: venous,
      yellowBg: markerBackground,
      redBg: markerBackgroundRed,
    };
    let bg = "yellowBg";
    if (type === "motion") {
      bg = "redBg";
    }
    return (
      <div
        style={{
          position: "absolute",
          top: "238px",
          // left: `${Math.random() * 200 + 40}px`,
          left: `${25 + time * ((width - 40) / 24)}px`,
          width: 30,
          height: 30,
          zIndex: 1000,
          cursor: "pointer",
          display: showConfidenceMessage ? "none" : "",
        }}
        onClick={() => {
          // console.log("clicked");
          setShowConfidenceMessage(true);
          setMessageType(type);
        }}
        draggable
      >
        <img
          src={iconCap[bg]}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <img
          src={iconCap[type]}
          style={{
            position: "absolute",
            width: "60%",
            height: "60%",
            objectFit: "cover",
            top: 10,
            left: 5,
          }}
        />
      </div>
    );
  };

  return (
    <Wrapper ref={ref}>
      {spo2ProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          // showTopImg={false}
        />
      ) : (
        <div>
          {/* {demoMarkerTime.map((x, i) => {
            return <Marker time={x} type={"marker"} key={i} />;
          })}
          {motionTimes.map((x, i) => {
            return <Marker time={x} type={"motion"} key={i} />;
          })}
          {demoVPMarkerTime.map((x, i) => {
            return <Marker time={x} type={"venous"} key={i} />;
          })} */}

          <ComposedChart
            width={width}
            height={height}
            data={spo2ProcessedData}
            onMouseMove={(e) => {
              // console.log(e);
              const { activeLabel } = e;
              // setActiveLabel(activeLabel);
            }}
            onMouseLeave={() => {
              // setActiveLabel(undefined);
            }}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              // strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
              // verticalCoordinatesGenerator={(props) => {
              //   const gridNum = 24;
              //   let _width = props.offset.width;
              //   let offset = props.offset.left;
              //   let originalDomainStart = props.xAxis.originalDomain[0];
              //   let originalDomainEnd = props.xAxis.originalDomain[1];
              //   let gridPos = [];

              //   for (let i = 0; i < gridNum; i++) {
              //     // gridPos.push(40 + (i + 1) * (_width / gridNum));
              //     // console.log(
              //     //   (1800000 * i) / (originalDomainEnd - originalDomainStart)
              //     // );

              //     gridPos.push(
              //       offset +
              //         ((xticks[i] + 1800000 - originalDomainStart) /
              //           (originalDomainEnd - originalDomainStart)) *
              //           _width
              //     );
              //   }
              //   return gridPos;
              // }}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              dataKey={"t"}
              ticks={xticks}
              tickSize={10}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"spo2"}
              ticks={yticks}
              domain={([min, max]) => [min < 80 ? min : 80, 100]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Tooltip content={<Spo2Tooltip />} />
            <Line
              type="monotone"
              // type="basis"
              dataKey="baseline"
              // name="Weighted"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2.5}
              connectNulls={true}
              // strokeDasharray="5 5"
              // hide={showLine["adpt"] ? false : true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
              // activeDot={false}
            />
            <Line
              type="monotone"
              dataKey="spo2"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              // connectNulls={true}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              // dot={dataLength < 100 ? true : false}
              // dot={DOT_STROKE}
              dot={false}
              isAnimationActive={false}
              // activeDot={false}
            />
            {activeLabel && (
              <ReferenceArea
                x1={
                  algorithm === 4
                    ? activeLabel - 24 * 60 * 60 * 1000
                    : activeLabel - 15 * 60 * 1000
                }
                x2={activeLabel}
                y1={0}
                y2={100}
                // stroke="red"
                // strokeOpacity={0.3}
                ifOverflow={"hidden"}
              />
            )}

            {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`spo2_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  stroke="#a0a0af90"
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  // hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })}
            {/* <Legend /> */}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              const _label =
                f.value === "weighted"
                  ? `${f.label} (${algoMap[algorithm]})`
                  : f.label;
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    $color={f.color}
                    $theme={theme}
                    $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  >
                    {_label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}

      {showConfidenceMessage === false ? null : (
        <NiceModal
          onClose={() => {
            setShowConfidenceMessage(false);
            setMessageType("");
          }}
        >
          {iconMessageMap[messageType]}
        </NiceModal>
      )}
    </Wrapper>
  );
};

export default PatientDaySpo2BaselineDebugsTool;

const iconMessageMap = {
  venous: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=105 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>
          Venous Pooling Alert
        </p>
        <p>
          We’ve detected possible signs of venous pooling. Venous pooling is the
          physiological condition in which lower oxygenated blood accumulates in
          the veins, particularly in the arm. This may happen if a patient lies
          on their arm, such as while lying on their side or if there’s
          restricted movement.
        </p>
        <p>
          Venous pooling presents a significant challenge for wrist-worn SpO2
          devices in reflective mode due to the anatomical properties of the
          wrist. The increased influence of venous signals on the reflective
          light path creates difficulties in isolating arterial pulsations.{" "}
        </p>
        <p>
          Corsano developed a new algorithm to differentiate between arterial
          and venous signals. If venous pooling is detected, data is discarded.
        </p>
      </div>
    </>
  ),
  motion: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=104 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>Motion Alert</p>
        <p>
          Reliable SpO2 values cannot be obtained during motion. Excessive
          movement interferes with sensor accuracy.
        </p>
      </div>
    </>
  ),
  marker: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=103 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>
          Low SpO2 Confidence Alert
        </p>
        <p>
          We have detected an episode of lower SPO2 data-input quality, which
          temporarily reduces our confidence in providing an accurate SPO2
          measurement. This can be caused by factors such as movement, improper
          sensor fit, or environmental conditions.
        </p>
        <p>To ensure accurate readings:</p>
        <ul>
          <li>
            Make sure the sensor is snug, but comfortable, slightly away from
            wrist bone
          </li>
          <li>Remove any jewelry near the bracelet.</li>
        </ul>
        <p>
          If the alert persists, please execute a spot SPO2 measurement in a
          sedentary position.
        </p>
      </div>
    </>
  ),
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  // height: 100%;
  height: 300px;
  position: relative;
`;

const Spo2Tooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw SPO2: ${payload[0].payload.spo2}`}</TooltipRow>
        {/* <TooltipRow className="label">{`Baseline: ${payload[0].payload.ma}`}</TooltipRow> */}
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.spo2_q}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.$color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
